// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/hero-alunos.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/hero-alunos-mobile.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion-class button {\n    background-color: rgb(239 141 52);\n    &:hover{\n        background-color: rgb(255 193 137);\n    }\n    &:focus{\n        background-color: rgb(255 193 137);\n    }\n}\n\n.bg-class{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n@media (max-width:600px) {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    \n}\n}", "",{"version":3,"sources":["webpack://./src/Modules/Accordionclass.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC;QACI,kCAAkC;IACtC;IACA;QACI,kCAAkC;IACtC;AACJ;;AAEA;IACI,yDAAmD;IACnD,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;IACI,yDAA0D;;AAE9D;AACA","sourcesContent":[".accordion-class button {\n    background-color: rgb(239 141 52);\n    &:hover{\n        background-color: rgb(255 193 137);\n    }\n    &:focus{\n        background-color: rgb(255 193 137);\n    }\n}\n\n.bg-class{\n    background-image: url('../images/hero-alunos.webp');\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n@media (max-width:600px) {\n    background-image: url('../images/hero-alunos-mobile.webp');\n    \n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
